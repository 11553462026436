import React from "react";

function HeaderTitle() {
  return (
    <>
    <div className="nine -mb-8">
  <h1 className="titleHeader">Dublin 841<span className="spanHeader">Peluqueria y Barberia</span></h1>
</div>
    </>
  );
}

export default HeaderTitle;
