import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import React from "react";
import ArrowButton from "./ArrowButton";

const navigation = [
  { name: "Home", href: "#", current: true },
  { name: "Turnos", href: "#turnos", current: false },
  { name: "Servicios", href: "#servicios", current: false },
  { name: "Contacto", href: "#contacto", current: false },
];

const filteredNavigation = navigation.slice(0, 1);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  function handleClick(name) {
    const element = refs[name];
    element.scrollIntoView({ behavior: "smooth" });
  }
  const refs = {};

  navigation.forEach((item) => {
    refs[item.name] = React.createRef();
  });
  return (
    <>
      <Disclosure
        as="nav"
        className="bg-gray-800 sticky top-0 bottom-0 left-0 right-0 z-50"
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-12 w-auto lg:hidden"
                      src="https://i.ibb.co/ZB8WM9m/DUBLINLOGO.jpg"
                      alt="Dublin841 Logo"
                    />
                    <NavLink
                      key={filteredNavigation.name}
                      to={filteredNavigation.href}
                      ref={refs[filteredNavigation.name]}
                      onClick={() => handleClick(filteredNavigation.name)}
                      aria-current={
                        filteredNavigation.current ? "page" : undefined
                      }
                    >
                      <img
                        className="hidden h-11 w-auto lg:block"
                        src="https://i.ibb.co/ZB8WM9m/DUBLINLOGO.jpg"
                        alt="Dublin841 Logo"
                      />
                    </NavLink>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          ref={refs[item.name]}
                          onClick={() => handleClick(item.name)}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <NavLink
                    to={item.href}
                    ref={refs[item.name]}
                    onClick={() => handleClick(item.name)}
                  >
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  </NavLink>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <ArrowButton />
    </>
  );
}
